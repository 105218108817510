/**
 * Tells if a given input is a number
 * @method
 * @memberof Popper.Utils
 * @param {*} input to check
 * @return {Boolean}
 */
export default function isNumeric(n) {
  return n !== '' &amp;&amp; !isNaN(parseFloat(n)) &amp;&amp; isFinite(n);
}
